import { ButtonClear, ButtonWide } from '@/components/button/Button';
import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import AuthIcon from '@/components/auth-icon/AuthIcon';
import { useState } from 'react';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import useNotifications from '@/src/hooks/useNotifications';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function AuthorizationByPhoneContractNotFound() {
    const { api } = useApiStore();
    const [submitting, setSubmitting] = useState(false);
    const { phone, setStep, setData } = useAuthorizationModal();
    const { showError } = useNotifications();

    const onRegistrationSend = async () => {
        setSubmitting(true);
        try {
            const time = await api.ott.sendOttRegistrationSMS(phone);
            setData({ time: time });
            setStep(AuthorizationModalStep.REGISTRATION_PHONE_CHECK_CODE);
        } catch (e) {
            showError(e.message);
        } finally {
            setSubmitting(false);
        }
    };

    const onCancel = () => {
        setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE);
    };

    return (
        <>
            <div className="auth__content-title">Ой! Что-то мы вас не узнаём.</div>
            <AuthIcon icon={'heart-broken'} />
            <div className="auth__content-subtitle">
                Если вы уверены, что у вас есть аккаунт Movix, проверьте номер телефона, либо зарегистрируйтесь.
            </div>
            <AuthBtnSet modifier={'auth__btnset'}>
                <div className="auth__wrapper-btn">
                    <ButtonWide
                        title={'Зарегистрироваться'}
                        type={'submit'}
                        onClick={onRegistrationSend}
                        submitting={submitting}
                    />
                </div>
                <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                    <ButtonClear title={'Попробовать ещё раз'} onClick={onCancel} />
                </div>
                <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                    <ButtonClear
                        type={'button'}
                        title={'Войти по номеру договора дом.ру'}
                        onClick={() => {
                            setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD);
                        }}
                    />
                </div>
            </AuthBtnSet>
        </>
    );
}
