import { useState } from 'react';
import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import useNotifications from '@/src/hooks/useNotifications';
import Button from '@/components/button/Button';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import AuthIcon from '@/components/auth-icon/AuthIcon';

export default function RegistrationAlreadyRegistered() {
    const { showError } = useNotifications();
    const { setStep, authByPhone } = useAuthorizationModal();
    const [submitting, setSubmitting] = useState(false);

    const onAuth = async () => {
        setSubmitting(true);
        try {
            await authByPhone();
        } catch (e) {
            showError(e.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <div className="auth__content-title">Мы вас узнали! Вы уже зарегистрированы в Movix.</div>
            <AuthIcon icon={'heart'} />
            <div className="auth__content-subtitle">Просто войдите в свой аккаунт</div>
            <AuthBtnSet modifier={'auth__btnset'}>
                <div className="auth__wrapper-btn">
                    <Button
                        title={'Получить СМС и войти'}
                        className={'button--large button--wide'}
                        onClick={onAuth}
                        submitting={submitting}
                    />
                </div>
                <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                    <Button
                        title={'Ввести другой номер'}
                        className={'button--clear'}
                        onClick={() => {
                            setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE);
                        }}
                    />
                </div>
                <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                    <Button
                        title={'Войти по номеру договора дом.ру'}
                        className={'button--clear'}
                        onClick={() => {
                            setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD);
                        }}
                    />
                </div>
            </AuthBtnSet>
        </>
    );
}
