import { useState } from 'react';
import Input from '@/components/input/Input';
import { ButtonClear, ButtonWide } from '@/components/button/Button';
import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function AuthorizationByPassword() {
    const { api } = useApiStore();
    const { login, setLogin, setStep, setContracts, setContractId, setRegion } = useAuthorizationModal();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const onSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSubmitting(true);
        try {
            const contracts = await api.generalInformation.getContractsByData(login);
            setContracts(contracts);
            if (contracts.length === 1) {
                const contract = contracts[0];
                const region = contract['domain'];
                const contractId = contract['agreement_number'];
                setContractId(contractId);
                setRegion({ extId: region });
                setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD_INPUT_PASSWORD);
            } else {
                setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD_SELECT_CONTRACT);
            }
        } catch (e) {
            setError(e.message);
        } finally {
            setSubmitting(false);
        }
    };

    const onLoginChange = (value) => {
        setLogin(value.trim());
        setError(null);
    };

    const onAuthByPhone = async (event) => {
        event.preventDefault();
        setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE);
    };

    return (
        <>
            <div className="auth__content-title">
                Введите номер договора
                <br />
                дом.ру или email
            </div>
            <form onSubmit={onSubmit}>
                <div className="auth__fieldset">
                    <div className="auth__field">
                        <Input
                            type="text"
                            className="input--modal"
                            regex={/[а-яё ]/gi}
                            value={login}
                            title="Номер договора или email"
                            error={error}
                            required="required"
                            onChange={onLoginChange}
                        />
                    </div>
                </div>
                <AuthBtnSet modifier={'auth__btnset'}>
                    <div className="auth__wrapper-btn">
                        <ButtonWide title={'Продолжить'} type={'submit'} submitting={submitting} disabled={!login} />
                    </div>
                    <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                        <ButtonClear title={'Войти по номеру телефона'} type={'button'} onClick={onAuthByPhone} />
                    </div>
                </AuthBtnSet>
            </form>
        </>
    );
}
