import { useEffect } from 'react';
import Router from 'next/router';
import AuthIcon from '@/components/auth-icon/AuthIcon';

export default function AuthorizationWelcome() {
    useEffect(() => {
        setTimeout(() => {
            Router.reload();
        }, 1000);
    }, []);

    return (
        <>
            <div className="auth__content-title">Добро пожаловать в Movix!</div>
            <AuthIcon icon={'success'} />
        </>
    );
}
