import styles from './AuthBg.module.scss';
import { emptyBackground } from '@/src/modules/Common';

export default function AuthBg({ modifier, src }) {
    return (
        <div className={styles['auth__bg'] + (modifier ? ' ' + modifier : '')}>
            {src && src !== emptyBackground ? (
                <picture className="auth__bg-img">
                    <img src={src} alt="" />
                </picture>
            ) : (
                <picture className="auth__bg-img auth__bg-img--fullscreen">
                    <source
                        type="image/webp"
                        srcSet="/assets/img/default-bg_1x.webp, /assets/img/default-bg_2x.webp 2x"
                        media="(min-width: 501px)"
                    />
                    <source
                        srcSet="/assets/img/default-bg_1x.jpg, /assets/img/default-bg_2x.jpg 2x"
                        media="(min-width: 501px)"
                    />
                    <img src="/assets/img/default-bg_1x.jpg" alt="" />
                </picture>
            )}
        </div>
    );
}
