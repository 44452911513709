import { useRef, useEffect } from 'react';
import Head from 'next/head';
import Common from '@/src/modules/Common';
import styles from './AuthForm.module.scss';
import useModals from '@/src/hooks/useModals';
import AuthBg from '@/components/auth-bg/AuthBg';
import AuthHeader from '@/components/auth-header/AuthHeader';
import AuthorizationFooter from '@/components/modal/authorization/AuthorizationFooter';

export default function AuthForm({ pageTitle, backgroundSrc, content, onHide }) {
    const { closeModal, isOpen } = useModals();
    const contentBlockRef = useRef(null);
    const innerBlockRef = useRef([]);

    const onWrapperKeyDown = (event) => {
        if (Common.isPressEscape(event)) {
            hide();
        }
    };

    const hide = () => {
        closeModal();

        if (onHide) {
            onHide.call();
        }
    };

    useEffect(() => {
        const contentBlock = /** @type {HTMLElement} */ contentBlockRef.current;
        const lastInnerBlock = innerBlockRef.current[content.length - 1];
        if (content.length > 1) {
            const isMobile = window.matchMedia('only screen and (max-width: 800px)').matches;
            const offset =
                -contentBlock.offsetTop -
                contentBlock.offsetHeight +
                40 +
                lastInnerBlock.offsetHeight +
                (isMobile ? 64 : 0);
            contentBlock.style.transform = `translateY(${offset}px`;
        } else {
            contentBlock.style.transform = null;
        }
    }, [content]);

    const contentModifier = content.length === 1 ? 'no_animation' : '';

    return (
        isOpen && (
            <div className={styles['auth'] + ' auth--fullscreen auth--show'} tabIndex="-1" onKeyDown={onWrapperKeyDown}>
                <Head>
                    <title>{pageTitle}</title>
                </Head>
                <AuthBg src={backgroundSrc} />
                <AuthHeader onCloseButtonClick={hide} modifier={content.length > 1 ? 'auth__header--fixed' : ''} />
                <div className={'auth__content' + (contentModifier ? ' ' + contentModifier : '')} ref={contentBlockRef}>
                    {content.map(({ block, id }, index) => (
                        <div
                            className="auth__content-inner"
                            key={`content-${id}`}
                            ref={(element) => (innerBlockRef.current[index] = element)}
                        >
                            <div className="auth__content-block">{block}</div>
                        </div>
                    ))}
                </div>
                <AuthorizationFooter />
            </div>
        )
    );
}
