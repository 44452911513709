import { useState } from 'react';
import { InputPassword } from '@/components/input/Input';
import Button, { ButtonClear, ButtonWide } from '@/components/button/Button';
import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import { afterLogin, loginByPassword } from '@/src/helpers/AuthHelper';
import useNotifications from '@/src/hooks/useNotifications';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function AuthorizationByPasswordInputPassword() {
    const { api } = useApiStore();
    const { showError } = useNotifications();
    const { setStep, contractId, region } = useAuthorizationModal();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSubmitting(true);
        try {
            await loginByPassword(api, contractId.toString(), password, region.extId);
            const errorMessage = await afterLogin(api);
            if (errorMessage) {
                showError(errorMessage);
            }
            setStep(AuthorizationModalStep.AUTHORIZATION_WELCOME);
        } catch (e) {
            setError(e.message);
        } finally {
            setSubmitting(false);
        }
    };

    const onPasswordChange = (value) => {
        setPassword(value);
        setError(null);
    };

    const onRestorePassword = async (event) => {
        event.preventDefault();
        setStep(AuthorizationModalStep.RESTORE_PASSWORD);
    };

    const onAuthByPhone = async (event) => {
        event.preventDefault();
        setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE);
    };

    return (
        <>
            <div className="auth__content-title">Введите пароль</div>
            <div className="auth__content-subtitle">
                Который привязан к номеру договора
                <br />
                {contractId}
                <Button
                    title={'Изменить'}
                    className={'button--clear-primal'}
                    onClick={() => setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD)}
                />
            </div>
            <form onSubmit={onSubmit} autoComplete={'off'}>
                <div className="auth__fieldset">
                    <div className="auth__field">
                        <InputPassword
                            title={'Пароль'}
                            value={password}
                            onChange={onPasswordChange}
                            className="input--modal"
                            regex={/[а-яё ]/gi}
                            name={'password'}
                            required="required"
                            error={error}
                            autoComplete={'new-password'}
                            onRestorePassword={onRestorePassword}
                        />
                    </div>
                </div>
                <AuthBtnSet modifier={'auth__btnset'}>
                    <div className="auth__wrapper-btn">
                        <ButtonWide title={'Войти'} type={'submit'} submitting={submitting} disabled={!password} />
                    </div>
                    <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                        <ButtonClear title={'Войти по номеру телефона'} type={'button'} onClick={onAuthByPhone} />
                    </div>
                </AuthBtnSet>
            </form>
        </>
    );
}
