import { useState } from 'react';
import { format } from '@/src/modules/Common';
import Button, { ButtonClear } from '@/components/button/Button';
import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import { afterLogin, loginBySMS } from '@/src/helpers/AuthHelper';
import useNotifications from '@/src/hooks/useNotifications';
import InputSms from '@/components/input/InputSms';
import { getTimeString } from '@/src/helpers/FormatHelper';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import Loader from '@/components/modal/helpers/Loader';
import useTimer from '@/src/hooks/useTimer';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function AuthorizationByPhoneCheckCode() {
    const { api } = useApiStore();
    const { showError } = useNotifications();
    const { phone, region, setStep, contractId, data } = useAuthorizationModal();
    const [submitting, setSubmitting] = useState(false);
    const [repeating, setRepeating] = useState(false);
    const [error, setError] = useState('');
    const [code, setCode] = useState('');
    const [secondsToRequestNewCode, setTimeout] = useTimer(data['time']);

    const onLogin = async (smsCode) => {
        setError('');
        try {
            setSubmitting(true);
            await loginBySMS(api, phone, region.extId, contractId, smsCode);
            const errorMessage = await afterLogin(api);
            if (errorMessage) {
                showError(errorMessage);
            }
            setStep(AuthorizationModalStep.AUTHORIZATION_WELCOME);
        } catch (e) {
            setError(e.message || 'Ошибка авторизации');
        } finally {
            setSubmitting(false);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        await onLogin(code);
    };

    const onRepeatSend = async (event) => {
        event.preventDefault();
        setRepeating(true);
        try {
            const response = await api.authorization.sendAuthSMS(phone, region.extId, contractId);
            setTimeout(response.timeUntilNext);
            setCode('');
            setError(response.SMSSent ? '' : response.SMSErrorText || 'Не удалось отправить СМС');
        } catch (e) {
            setError(e.message);
        } finally {
            setRepeating(false);
        }
    };

    const onChange = (value) => {
        setError('');
        setCode(value);
        if (value.length === 4) {
            // noinspection JSIgnoredPromiseFromCall
            onLogin(value);
        }
    };

    const onAuthByPhone = async (event) => {
        event.preventDefault();
        setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE);
    };

    const onAuthByPassword = async (event) => {
        event.preventDefault();
        setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD);
    };

    return (
        <>
            <div className="auth__content-title">Для входа введите код из СМС</div>
            <div className="auth__content-subtitle">
                Отправили его на номер
                <br />
                {format(phone.slice(1), '+7 (###) ###-##-##')}
                <Button
                    title={'Изменить'}
                    className={'button--clear-primal'}
                    onClick={() => setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE)}
                />
            </div>
            <form onSubmit={onSubmit}>
                <div className="auth__fieldset">
                    <div className="auth__field">
                        {submitting ? <Loader /> : <InputSms size={4} value={code} error={error} onChange={onChange} />}
                    </div>
                </div>
                {secondsToRequestNewCode === undefined && (
                    <>
                        <div className="auth__errorset auth__secondary-text">
                            <div className="auth__error-descr">
                                Увы, СМС на сегодня не осталось. За 24 часа можно отправить только 3 СМС с кодом.
                            </div>
                        </div>
                        <AuthBtnSet modifier={'auth__btnset'}>
                            <div className="auth__wrapper-btn">
                                <Button
                                    title={'Вернуться на главный экран'}
                                    className={'button--large button--wide button--secondary'}
                                    onClick={onAuthByPhone}
                                />
                            </div>
                            <div className="auth__wrapper-btn auth__wrapper-btn--indented auth__wrapper-btn--indented-low">
                                <ButtonClear
                                    type={'button'}
                                    title={'Войти по номеру договора дом.ру'}
                                    onClick={onAuthByPassword}
                                />
                            </div>
                        </AuthBtnSet>
                    </>
                )}
                {secondsToRequestNewCode > 0 && !submitting && (
                    <div className="auth__timer auth__secondary-text">
                        Получить новый код можно через {getTimeString(secondsToRequestNewCode)}
                    </div>
                )}
                {secondsToRequestNewCode <= 0 && !submitting && (
                    <AuthBtnSet modifier={'auth__btnset--low'}>
                        <div className="auth__wrapper-btn">
                            <Button
                                className={'button--clear'}
                                title={'Получить новый код'}
                                onClick={onRepeatSend}
                                submitting={repeating}
                            />
                        </div>
                    </AuthBtnSet>
                )}
            </form>
        </>
    );
}
