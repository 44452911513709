import styles from './AuthIcon.module.scss';

export default function AuthIcon({ modifier, icon = 'heart' }) {
    return (
        <div className={styles['auth__icon'] + (modifier ? ' ' + modifier : '')}>
            <img src="/assets/img/icon-bg.png" alt="" />
            {icon === 'heart' && (
                <img className="auth__icon-img auth__icon-img--found" src="/assets/img/icon-heart.png" alt="" />
            )}
            {icon === 'heart-broken' && (
                <img className="auth__icon-img" src="/assets/img/icon-heart-broken.png" alt="" />
            )}
            {icon === 'success' && (
                <img className="auth__icon-img auth__icon-img--pulse" src="/assets/img/icon-success.png" alt="" />
            )}
        </div>
    );
}
