import styles from './AuthHeader.module.scss';
import Icon from '@/components/icon/Icon';

export default function AuthHeader({ modifier, onCloseButtonClick }) {
    return (
        <div className={styles['auth__header'] + (modifier ? ' ' + modifier : '')}>
            <div className="auth__header-inner">
                <img className="auth__header-logo" src="/assets/img/logo.svg" alt="Movix" />
                <button className="auth__close" type="button" onClick={onCloseButtonClick}>
                    <span className="h-hidden--text">Закрыть модальное окно</span>
                    <Icon id={'close'} modifier={'icon--close auth__close-icon'} />
                </button>
            </div>
        </div>
    );
}
