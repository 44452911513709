import { useState } from 'react';
import Input from '@/components/input/Input';
import Button, { ButtonClear } from '@/components/button/Button';
import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import ApiBackendException from '@/src/api/ApiBackendException';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function AuthorizationByPhone({ title }) {
    const { api } = useApiStore();
    const { phone, setPhone, setStep, setData, authByPhone } = useAuthorizationModal();

    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [registrationSubmitting, setRegistrationSubmitting] = useState(false);

    const onPhoneChange = (value) => {
        const newPhone = value.replace(/\D/g, '');
        setPhone(newPhone);
        setError('');
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSubmitting(true);
        try {
            await authByPhone();
        } catch (e) {
            setError(e.message);
        } finally {
            setSubmitting(false);
        }
    };

    const onRegistrationSend = async (event) => {
        event.preventDefault();
        setRegistrationSubmitting(true);
        setError('');
        try {
            const time = await api.ott.sendOttRegistrationSMS(phone);
            setData({ time: time });
            setStep(AuthorizationModalStep.REGISTRATION_PHONE_CHECK_CODE);
        } catch (e) {
            if (
                e instanceof ApiBackendException &&
                ['LOGIN_ALREADY_ACTIVE', 'PHONE_NUMBER_ALREADY_ACTIVE'].includes(e.code)
            ) {
                setStep(AuthorizationModalStep.REGISTRATION_ALREADY_REGISTERED);
            } else {
                setError(e.message);
            }
        } finally {
            setRegistrationSubmitting(false);
        }
    };

    const onAuthByPassword = async (event) => {
        event.preventDefault();
        setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD);
    };

    const isPhoneValid = /7\d{10}/.test(phone);

    return (
        <>
            <div className="auth__content-title">{title || 'Войдите или зарегистрируйтесь'}</div>
            <form onSubmit={onSubmit}>
                <div className="auth__fieldset">
                    <div className="auth__field">
                        <Input
                            type="text"
                            className="input--modal"
                            mask="+7 (999) 999-99-99"
                            value={phone}
                            error={error}
                            title={'Номер телефона'}
                            required="required"
                            onChange={onPhoneChange}
                        />
                    </div>
                </div>
                <AuthBtnSet modifier={'auth__btnset'}>
                    <div className="auth__wrapper-btn">
                        <Button
                            title={'Войти'}
                            className={'button--large button--wide'}
                            type="submit"
                            submitting={submitting}
                            disabled={!isPhoneValid}
                        />
                    </div>
                    <div className="auth__wrapper-text">или</div>
                    <div className="auth__wrapper-btn">
                        <Button
                            title={'Зарегистрироваться'}
                            className={'button--large button--wide button--secondary'}
                            disabled={!isPhoneValid}
                            onClick={onRegistrationSend}
                            submitting={registrationSubmitting}
                        />
                    </div>
                    <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                        <ButtonClear
                            type={'button'}
                            title={'Войти по номеру договора дом.ру'}
                            onClick={onAuthByPassword}
                        />
                    </div>
                </AuthBtnSet>
            </form>
        </>
    );
}
