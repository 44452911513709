import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import Button from '@/components/button/Button';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import { format } from '@/src/modules/Common';

export default function AuthorizationByPasswordSelectContract() {
    const { login, contracts, setLogin, setStep, setContractId, setRegion } = useAuthorizationModal();

    const onSubmit = async (event) => {
        event.preventDefault();
    };

    const onSelectContract = (contract) => {
        const region = contract['domain'];
        const contractId = contract['agreement_number'];
        setContractId(contractId);
        setRegion({ extId: region });
        setLogin(contractId);
        setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD_INPUT_PASSWORD);
    };

    let text, loginFormat;
    if (login.includes('@')) {
        text = 'адресу email';
        loginFormat = login;
    } else if (login.length === 11) {
        text = 'номеру телефона';
        loginFormat = format(login.slice(1), '+7 (###) ###-##-##');
    } else {
        text = 'номеру договора';
        loginFormat = login;
    }

    return (
        <>
            <div className="auth__content-title">Выберите договор</div>
            <div className="auth__content-subtitle">
                Мы нашли несколько договоров, привязанных к&nbsp;вашему {text}
                <br />
                {loginFormat}
                <Button
                    title={'Изменить'}
                    className={'button--clear-primal'}
                    onClick={() => setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD)}
                />
            </div>
            <form onSubmit={onSubmit}>
                <AuthBtnSet modifier={'auth__btnset'}>
                    <div className="auth-btnset__inner">
                        {contracts.map((contract) => (
                            <div className="auth__arrow-btn" key={contract['agreement_number']}>
                                <Button
                                    type={'submit'}
                                    className={'button--wide button--clear auth__btn--arrow'}
                                    title={contract['agreement_number']}
                                    icon={'arrow-right'}
                                    onClick={() => onSelectContract(contract)}
                                />
                            </div>
                        ))}
                    </div>
                </AuthBtnSet>
            </form>
        </>
    );
}
