import { useRef, useState } from 'react';
import Input from '@/components/input/Input';
import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import { ButtonClear, ButtonWide } from '@/components/button/Button';
import useNotifications from '@/src/hooks/useNotifications';
import { replaceAll } from '@/src/helpers/PolyfillHelper';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function RestorePassword() {
    const { api } = useApiStore();
    const { login, setLogin, setStep, setContacts, setContractId, setRegion, setContracts } = useAuthorizationModal();
    const { showError } = useNotifications();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const loginRef = useRef(login);

    const onSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSubmitting(true);
        try {
            const contracts = await api.generalInformation.getContractsByData(login);
            setContracts(contracts);
            if (contracts.length === 0) {
                setError('Проверьте правильность ввода номера договора и повторите снова');
            } else if (contracts.length === 1) {
                const contract = contracts[0];
                const contacts = await api.authorization.getSSOAuthRecoveryContacts(
                    contract['agreement_number'],
                    contract['domain']
                );
                if (contacts.length === 0) {
                    const supportPhone = replaceAll(await api.generalInformation.getSupportPhone(), '-', ' ');
                    showError(
                        `Для восстановления пароля, пожалуйста обратитесь в контакт центр по номеру ${supportPhone}`
                    );
                } else {
                    setContacts(contacts);
                    setContractId(contract['agreement_number']);
                    setRegion({ extId: contract['domain'] });
                    setStep(AuthorizationModalStep.RESTORE_PASSWORD_SELECT_CONTACT);
                }
            } else {
                setStep(AuthorizationModalStep.RESTORE_PASSWORD_SELECT_CONTRACT);
            }
        } catch (e) {
            setError(e.message);
        } finally {
            setSubmitting(false);
        }
    };

    const onLoginChange = (value) => {
        setLogin(value.trim());
    };

    const onInputPassword = async (event) => {
        event.preventDefault();
        setLogin(loginRef.current);
        setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD_INPUT_PASSWORD);
    };

    const onAuthByPhone = async (event) => {
        event.preventDefault();
        setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE);
    };

    return (
        <>
            <div className="auth__content-title">Восстановление пароля</div>
            <div className="auth__content-subtitle">
                Введите ваш номер телефона,
                <br />
                договора дом.ру или email
            </div>
            <form onSubmit={onSubmit}>
                <div className="auth__fieldset">
                    <div className="auth__field">
                        <Input
                            type="text"
                            className="input--modal"
                            regex={/[а-яё ]/gi}
                            value={login}
                            title="Номер телефона, договора или email"
                            error={error}
                            required="required"
                            onChange={onLoginChange}
                        />
                    </div>
                </div>
                <AuthBtnSet modifier={'auth__btnset'}>
                    <div className="auth__wrapper-btn">
                        <ButtonWide title={'Продолжить'} type={'submit'} submitting={submitting} disabled={!login} />
                    </div>
                    <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                        <ButtonClear
                            title={'А нет, кажется, помню пароль!'}
                            type={'button'}
                            onClick={onInputPassword}
                        />
                    </div>
                    <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                        <ButtonClear title={'Войти по номеру телефона'} type={'button'} onClick={onAuthByPhone} />
                    </div>
                </AuthBtnSet>
            </form>
        </>
    );
}
