import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import Button, { ButtonClear } from '@/components/button/Button';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import { useState } from 'react';
import useNotifications from '@/src/hooks/useNotifications';
import { format } from '@/src/modules/Common';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function RestorePasswordSelectContract() {
    const { api } = useApiStore();
    const { login, contracts, setStep, setContacts, setContractId, setRegion } = useAuthorizationModal();
    const { showError } = useNotifications();
    const [submittingContractIndex, setSubmittingContractIndex] = useState(-1);

    const onSubmit = async (event) => {
        event.preventDefault();
    };

    const onSelectContract = async (contract, index) => {
        if (submittingContractIndex !== -1) {
            return;
        }
        const region = contract['domain'];
        const contractId = contract['agreement_number'];
        setSubmittingContractIndex(index);
        try {
            const contacts = await api.authorization.getSSOAuthRecoveryContacts(contractId, region);
            setContacts(contacts);
            setContractId(contractId);
            setRegion({ extId: region });
            setStep(AuthorizationModalStep.RESTORE_PASSWORD_SELECT_CONTACT);
        } catch (e) {
            showError(e.message);
            setSubmittingContractIndex(-1);
        }
    };

    return (
        <>
            <div className="auth__content-title">
                Выберите договор
                <br />
                для восстановления пароля
            </div>
            <div className="auth__content-subtitle">
                Мы нашли несколько договоров, привязанных к&nbsp;{' '}
                {login.includes('@') ? 'вашему email адресу' : ' вашему телефону'}
                <br />
                {login.includes('@') ? login : format(login.slice(1), '+7 (###) ###-##-##')}
                <Button
                    title={'Изменить'}
                    className={'button--clear-primal'}
                    onClick={() => setStep(AuthorizationModalStep.RESTORE_PASSWORD)}
                />
            </div>
            <form onSubmit={onSubmit}>
                <AuthBtnSet modifier={'auth__btnset'}>
                    <div className="auth-btnset__inner">
                        {contracts.map((contract, index) => (
                            <div className="auth__arrow-btn" key={contract['agreement_number']}>
                                <Button
                                    type={'submit'}
                                    className={'button--wide button--clear auth__btn--arrow'}
                                    title={contract['agreement_number']}
                                    icon={'arrow-right'}
                                    onClick={() => onSelectContract(contract, index)}
                                    submitting={submittingContractIndex === index}
                                />
                            </div>
                        ))}
                    </div>
                </AuthBtnSet>
                <AuthBtnSet modifier={'auth__btnset auth__btnset--low'}>
                    <div className="auth__wrapper-btn">
                        <ButtonClear
                            title={'А нет, кажется, помню пароль!'}
                            type={'button'}
                            onClick={() => setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD_INPUT_PASSWORD)}
                        />
                    </div>
                    <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                        <ButtonClear
                            title={'Войти по номеру телефона'}
                            type={'button'}
                            onClick={() => setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE)}
                        />
                    </div>
                </AuthBtnSet>
            </form>
        </>
    );
}
