import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import Button, { ButtonClear } from '@/components/button/Button';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import { useState } from 'react';
import useNotifications from '@/src/hooks/useNotifications';
import { replaceAll } from '@/src/helpers/PolyfillHelper';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function RestorePasswordSelectContact() {
    const { api } = useApiStore();
    const { contractId, contacts, region, setStep, setLogin } = useAuthorizationModal();
    const { showError } = useNotifications();
    const [submittingContactId, setSubmittingContactId] = useState(-1);

    const onSubmit = async (event) => {
        event.preventDefault();
    };

    const onSelectContact = async (contact) => {
        setSubmittingContactId(contact['contact_id']);
        try {
            await api.authorization.sendAuthRecovery(
                contact['agreement_id'],
                region['extId'],
                contact['contact_type'],
                contact['contact_id']
            );
            setLogin(contact['agreement_number'].toString());
            setStep(AuthorizationModalStep.RESTORE_PASSWORD_PASSWORD_SENT);
        } catch (e) {
            showError(e.message);
            setSubmittingContactId(-1);
        }
    };

    const phones = contacts.filter((contact) => contact['contact_type'] === 2);
    const emails = contacts.filter((contact) => contact['contact_type'] === 1);

    return (
        <>
            <div className="auth__content-title">
                Выберите номер телефона
                <br />
                или email
            </div>
            <div className="auth__content-subtitle">
                Мы пришлём новый пароль к договору
                <br />
                {contractId}
                <Button
                    title={'Изменить'}
                    className={'button--clear-primal'}
                    onClick={() => setStep(AuthorizationModalStep.RESTORE_PASSWORD)}
                />
            </div>
            <form onSubmit={onSubmit}>
                {phones.length > 0 && (
                    <AuthBtnSet modifier={'auth__btnset'}>
                        <div className="auth-btnset__title">Телефон</div>
                        <div className="auth-btnset__inner">
                            {phones.map((contact) => (
                                <div className="auth__arrow-btn" key={contact['contact_id']}>
                                    <Button
                                        type={'submit'}
                                        className={'button--wide button--clear auth__btn--arrow'}
                                        title={replaceAll(contact['row_text'], '*', '•')}
                                        icon={'arrow-right'}
                                        onClick={() => onSelectContact(contact)}
                                        submitting={submittingContactId === contact['contact_id']}
                                    />
                                </div>
                            ))}
                        </div>
                    </AuthBtnSet>
                )}
                {emails.length > 0 && (
                    <AuthBtnSet modifier={'auth__btnset' + (phones.length > 0 ? ' auth__btnset--low' : '')}>
                        <div className="auth-btnset__title">Email</div>
                        <div className="auth-btnset__inner">
                            {emails.map((contact) => (
                                <div className="auth__arrow-btn" key={contact['contact_id']}>
                                    <Button
                                        type={'submit'}
                                        className={'button--wide button--clear auth__btn--arrow'}
                                        title={replaceAll(contact['row_text'], '*', '•')}
                                        icon={'arrow-right'}
                                        onClick={() => onSelectContact(contact)}
                                        submitting={submittingContactId === contact['contact_id']}
                                    />
                                </div>
                            ))}
                        </div>
                    </AuthBtnSet>
                )}
                <AuthBtnSet modifier={'auth__btnset auth__btnset--low'}>
                    <div className="auth__wrapper-btn">
                        <ButtonClear
                            title={'А нет, кажется, помню пароль!'}
                            type={'button'}
                            onClick={() => setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD_INPUT_PASSWORD)}
                        />
                    </div>
                    <div className="auth__wrapper-btn auth__wrapper-btn--indented">
                        <ButtonClear
                            title={'Войти по номеру телефона'}
                            type={'button'}
                            onClick={() => setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE)}
                        />
                    </div>
                </AuthBtnSet>
            </form>
        </>
    );
}
