import styles from '../../auth/AuthForm.module.scss';

export default function AuthorizationFooter() {
    return (
        <div className={styles['auth__footnote']}>
            Продолжая, вы соглашаетесь с{' '}
            <a className="auth__footnote-link" target="_blank" rel="nofollow noopener noreferrer" href="/offer">
                пользовательским соглашением
            </a>
            {' и '}
            <a
                className="auth__footnote-link"
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="/personal_data_policy"
            >
                политикой об обработке персональных данных
            </a>
        </div>
    );
}
