import { createContext, useState, useContext } from 'react';
import ApiBackendException from '@/src/api/ApiBackendException';
import { useApiStore } from '@/src/stores/RootStoreProvider';

/**
 * @typedef {Object} AuthorizationModalContextType
 * @property {string} phone - Номер телефона
 * @property {(value: (((prevState: string) => string) | string)) => void} setPhone - Установить телефон
 * @property {string} step - Шаг
 * @property {(value: (((prevState: string) => string) | string)) => void} setStep - Установить шаг
 * @property {Object} region - Регион
 * @property {(value: (((prevState: Object) => Object) | Object)) => void} setRegion - Установить регион
 * @property {Object[]} contracts - Список договоров
 * @property {(value: (((prevState: Object[]) => Object[]) | Object[])) => void} setContracts - Установить список договоров
 * @property {number} contractId - Выбранный ID договора
 * @property {(value: (((prevState: number) => number) | number)) => void} setContractId - Установить ID договора
 * @property {Object[]} regionsFilter - Список регионов для выбора
 * @property {(value: (((prevState: Object[]) => Object[]) | Object[])) => void} setRegionsFilter - Установить список регинонов
 * @property {string} login - Логин
 * @property {(value: (((prevState: string) => string) | string)) => void} setLogin - Установить логин
 * @property {Object[]} contacts - Список контактов (для восстановления пароля)
 * @property {(value: (((prevState: Object[]) => Object[]) | Object[])) => void} setContacts - Установить список контактов
 * @property {number} contactIndex - Выбранный индекс контакта
 * @property {(value: (((prevState: number) => number) | number)) => void} setContactIndex - Установить индекс контакта
 * */

/**
 * @type {React.Context<AuthorizationModalContextType>}
 */
const AuthorizationModalContext = createContext({});

export const AuthorizationModalStep = {
    AUTHORIZATION_BY_PHONE: 'AuthorizationByPhone',
    AUTHORIZATION_BY_PHONE_SELECT_CONTRACT: 'AuthorizationByPhoneSelectContract',
    AUTHORIZATION_BY_PHONE_CONTRACT_NOT_FOUND: 'AuthorizationByPhoneContractNotFound',
    AUTHORIZATION_BY_PHONE_CHECK_CODE: 'AuthorizationByPhoneCheckCode',
    AUTHORIZATION_BY_PASSWORD: 'AuthorizationByPassword',
    AUTHORIZATION_BY_PASSWORD_INPUT_PASSWORD: 'AuthorizationByPasswordInputPassword',
    AUTHORIZATION_BY_PASSWORD_SELECT_CONTRACT: 'AuthorizationByPasswordSelectContract',
    AUTHORIZATION_WELCOME: 'AuthorizationWelcome',
    REGISTRATION_PHONE_CHECK_CODE: 'RegistrationPhoneCheckCode',
    REGISTRATION_ALREADY_REGISTERED: 'RegistrationAlreadyRegistered',
    RESTORE_PASSWORD: 'RestorePassword',
    RESTORE_PASSWORD_SELECT_CONTACT: 'RestorePasswordSelectContact',
    RESTORE_PASSWORD_SELECT_CONTRACT: 'RestorePasswordSelectContract',
    RESTORE_PASSWORD_PASSWORD_SENT: 'RestorePasswordPasswordSent',
};

export function AuthorizationModalProvider({ children }) {
    const { api } = useApiStore();
    const [phone, setPhone] = useState('');
    const [step, setStep] = useState(AuthorizationModalStep.AUTHORIZATION_BY_PHONE);
    const [steps, setSteps] = useState([AuthorizationModalStep.AUTHORIZATION_BY_PHONE]);
    const [region, setRegion] = useState({});
    const [contracts, setContracts] = useState([]);
    const [regionsFilter, setRegionsFilter] = useState([]);
    const [contractId, setContractId] = useState(undefined);
    const [login, setLogin] = useState('');
    const [contacts, setContacts] = useState([]);
    const [contactIndex, setContactIndex] = useState();
    const [data, setData] = useState({});

    const setStepGA = (newStep) => {
        setStep(newStep);
        if (
            [AuthorizationModalStep.AUTHORIZATION_BY_PHONE, AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD].includes(
                newStep
            )
        ) {
            setSteps([newStep]);
        } else {
            const index = steps.indexOf(newStep);
            if (index === -1) {
                setSteps([...steps, newStep]);
            } else {
                setSteps(steps.slice(0, index + 1));
            }
        }
    };

    const checkAuthSMS = async (domain) => {
        setRegion({ extId: domain });
        const response = await api.authorization.sendAuthSMS(phone, domain);
        if (response.SMSSent || ['SMS_ALREADY_SENT', 'SMS_TOO_MANY_SENT'].includes(response.SMSErrorCode)) {
            setContracts(response.contracts);
            setContractId(response.contracts[0].id);
            setData({ time: response.timeUntilNext });
            setStepGA(AuthorizationModalStep.AUTHORIZATION_BY_PHONE_CHECK_CODE);
        } else {
            throw new Error(response.SMSErrorText || 'Не удалось отправить СМС');
        }
    };

    const authByPhone = async () => {
        try {
            const contracts = await api.generalInformation.getContractsByPhone(phone);
            setContracts(contracts);
            if (contracts.length === 1) {
                await checkAuthSMS(contracts[0].domain);
            } else {
                setStepGA(AuthorizationModalStep.AUTHORIZATION_BY_PHONE_SELECT_CONTRACT);
            }
        } catch (e) {
            if (e instanceof ApiBackendException) {
                const apiError = e.apiError;
                if (apiError.message === 'agreements not found') {
                    setStepGA(AuthorizationModalStep.AUTHORIZATION_BY_PHONE_CONTRACT_NOT_FOUND);
                    return;
                }
            }
            throw new Error(e.message);
        }
    };

    return (
        <AuthorizationModalContext.Provider
            value=/* NOSONAR */ {{
                phone,
                setPhone,
                step,
                steps,
                setStep: setStepGA,
                region,
                setRegion,
                contracts,
                setContracts,
                contractId,
                setContractId,
                regionsFilter,
                setRegionsFilter,
                login,
                setLogin,
                contacts,
                setContacts,
                contactIndex,
                setContactIndex,
                data,
                setData,
                authByPhone,
            }}
        >
            {children}
        </AuthorizationModalContext.Provider>
    );
}

export const useAuthorizationModal = () => useContext(AuthorizationModalContext);
