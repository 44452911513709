import { useRef } from 'react';
import styles from './Input.module.scss';

const keyBackspace = 8;

const stringReplaceAt = (str, index, replacement) => {
    return str.substring(0, index) + replacement + str.substring(index + 1);
};

// TODO: Временный компонент, переделать на один Input
export default function InputSms({ size = 8, value, error, onChange }) {
    const digitsRef = useRef([]);

    const inputOnChange = (event) => {
        const digit = event.target.value.slice(0, 1);
        const index = event.target.dataset.id;
        const newValue = stringReplaceAt(value, index, digit);

        if (onChange) {
            onChange(newValue);
        }

        if (digit !== '') {
            if (event.target.nextSibling) {
                event.target.nextSibling.focus();
            }
        }
    };

    const onKeyDown = (event) => {
        switch (event.keyCode /* NOSONAR */) {
            case keyBackspace:
                if (!event.target.value) {
                    onChange(value.slice(0, -1));
                }
                setTimeout(() => {
                    onFocus();
                }, 0);
                break;
            default:
                if (!/\d/.test(event.key)) {
                    event.preventDefault();
                }
        }
    };

    const onFocus = () => {
        for (const input of digitsRef.current) {
            if (input.value === '') {
                input.focus();
                return;
            }
        }
    };

    return (
        <div className={'input input--code' + (error ? ' input--code-error' : '')}>
            <div className={'input__pad'} onFocus={onFocus}>
                {[...Array(size).keys()].map((digit) => (
                    <input
                        key={`digit-${digit}`}
                        className={styles['input__code'] + (value[digit] ? ' input__code--full' : '')}
                        type={'number'}
                        min={0}
                        max={9}
                        autoComplete="off"
                        required={true}
                        value={value[digit] || ''}
                        onChange={inputOnChange}
                        onKeyDown={onKeyDown}
                        placeholder={'•'}
                        ref={(ref) => (digitsRef.current[digit] = ref)}
                        data-id={digit}
                    />
                ))}
                <div className="input__bg"></div>
            </div>
            {error && <p className="input__helptext input__helptext--warning">{error}</p>}
        </div>
    );
}
