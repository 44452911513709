import { useEffect } from 'react';
import AuthIcon from '@/components/auth-icon/AuthIcon';
import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';

export default function RestorePasswordPasswordSent() {
    const { setStep } = useAuthorizationModal();

    useEffect(() => {
        setTimeout(() => {
            setStep(AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD_INPUT_PASSWORD);
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="auth__content-title">Отправили вам новый пароль</div>
            <AuthIcon icon={'success'} />
        </>
    );
}
