import { useEffect } from 'react';
import AuthForm from '@/components/auth/AuthForm';
import {
    AuthorizationModalProvider,
    AuthorizationModalStep,
    useAuthorizationModal,
} from '@/src/hooks/useAuthorizationModal';
import AuthorizationByPhone from '@/components/modal/authorization/AuthorizationByPhone';
import AuthorizationByPhoneContractNotFound from '@/components/modal/authorization/AuthorizationByPhoneContractNotFound';
import RegistrationPhoneCheckCode from '@/components/modal/authorization/RegistrationPhoneCheckCode';
import AuthorizationByPhoneCheckCode from '@/components/modal/authorization/AuthorizationByPhoneCheckCode';
import AuthorizationByPassword from '@/components/modal/authorization/AuthorizationByPassword';
import RestorePassword from '@/components/modal/authorization/RestorePassword';
import RestorePasswordSelectContact from '@/components/modal/authorization/RestorePasswordSelectContact';
import AuthorizationByPasswordInputPassword from '@/components/modal/authorization/AuthorizationByPasswordInputPassword';
import AuthorizationByPasswordSelectContract from '@/components/modal/authorization/AuthorizationByPasswordSelectContract';
import AuthorizationByPhoneSelectContract from '@/components/modal/authorization/AuthorizationByPhoneSelectContract';
import AuthorizationWelcome from '@/components/modal/authorization/AuthorizationWelcome';
import Router from 'next/router';
import RestorePasswordPasswordSent from '@/components/modal/authorization/RestorePasswordPasswordSent';
import RestorePasswordSelectContract from '@/components/modal/authorization/RestorePasswordSelectContract';
import RegistrationAlreadyRegistered from '@/components/modal/authorization/RegistrationAlreadyRegistered';
import { modalViewEvent } from '@/src/modules/events/GTM';

function ModalAuthForm({ title, backgroundSrc }) {
    const { step, steps } = useAuthorizationModal();

    useEffect(() => {
        const items = document.getElementsByClassName('auth__content-inner');
        const content = items[items.length - 1];
        const focusable = content.querySelectorAll('[href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        if (focusable.length) {
            const timeout = [
                AuthorizationModalStep.AUTHORIZATION_BY_PHONE,
                AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD,
            ].includes(step)
                ? 0
                : 700;
            setTimeout(() => {
                focusable[0].focus();
            }, timeout);
        }
        modalViewEvent(step, 'authorization');
    }, [step]);

    const getContent = (contentStep) => {
        switch (contentStep) {
            case AuthorizationModalStep.AUTHORIZATION_BY_PHONE:
                return <AuthorizationByPhone title={title} />;
            case AuthorizationModalStep.AUTHORIZATION_BY_PHONE_CONTRACT_NOT_FOUND:
                return <AuthorizationByPhoneContractNotFound />;
            case AuthorizationModalStep.AUTHORIZATION_BY_PHONE_CHECK_CODE:
                return <AuthorizationByPhoneCheckCode />;
            case AuthorizationModalStep.AUTHORIZATION_BY_PHONE_SELECT_CONTRACT:
                return <AuthorizationByPhoneSelectContract />;
            case AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD:
                return <AuthorizationByPassword />;
            case AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD_INPUT_PASSWORD:
                return <AuthorizationByPasswordInputPassword />;
            case AuthorizationModalStep.AUTHORIZATION_BY_PASSWORD_SELECT_CONTRACT:
                return <AuthorizationByPasswordSelectContract />;
            case AuthorizationModalStep.AUTHORIZATION_WELCOME:
                return <AuthorizationWelcome />;
            case AuthorizationModalStep.REGISTRATION_PHONE_CHECK_CODE:
                return <RegistrationPhoneCheckCode />;
            case AuthorizationModalStep.REGISTRATION_ALREADY_REGISTERED:
                return <RegistrationAlreadyRegistered />;
            case AuthorizationModalStep.RESTORE_PASSWORD:
                return <RestorePassword />;
            case AuthorizationModalStep.RESTORE_PASSWORD_SELECT_CONTACT:
                return <RestorePasswordSelectContact />;
            case AuthorizationModalStep.RESTORE_PASSWORD_SELECT_CONTRACT:
                return <RestorePasswordSelectContract />;
            case AuthorizationModalStep.RESTORE_PASSWORD_PASSWORD_SENT:
                return <RestorePasswordPasswordSent />;
            default:
                return <AuthorizationByPhone title={title} />;
        }
    };

    const onHide = () => {
        if (step === AuthorizationModalStep.AUTHORIZATION_WELCOME) {
            Router.reload();
        }
    };

    let content = [];
    steps.forEach((step) => {
        content.push({
            id: step,
            block: getContent(step),
        });
    });

    return (
        <AuthForm
            pageTitle={'Вход в онлайн-кинотеатр Дом.ру Movix'}
            backgroundSrc={backgroundSrc}
            content={content}
            onHide={onHide}
        />
    );
}

export default function ModalAuthorization({ title, backgroundSrc }) {
    return (
        <AuthorizationModalProvider>
            <ModalAuthForm title={title} backgroundSrc={backgroundSrc} />
        </AuthorizationModalProvider>
    );
}
