import { AuthorizationModalStep, useAuthorizationModal } from '@/src/hooks/useAuthorizationModal';
import Button from '@/components/button/Button';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import { format } from '@/src/modules/Common';
import { useState } from 'react';
import useNotifications from '@/src/hooks/useNotifications';
import { useApiStore } from '@/src/stores/RootStoreProvider';

export default function AuthorizationByPhoneSelectContract() {
    const { api } = useApiStore();
    const { phone, contracts, setRegion, setStep, setContractId, setData } = useAuthorizationModal();
    const { showError } = useNotifications();
    const [submittingContractIndex, setSubmittingContractIndex] = useState(-1);

    const onSubmit = async (event) => {
        event.preventDefault();
    };

    const onSelectContract = async (contract, index) => {
        const region = contract['domain'];
        const contractId = contract['agreement_id'];
        setSubmittingContractIndex(index);
        setRegion({ extId: region });
        setContractId(contractId);
        try {
            const response = await api.authorization.sendAuthSMS(phone, region, contractId);
            if (response.SMSSent) {
                setData({ time: response.timeUntilNext });
                setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE_CHECK_CODE);
            } else {
                showError(response.SMSErrorText || 'Не удалось отправить СМС');
            }
        } catch (e) {
            showError(e.message || 'Ошибка при отправке СМС');
        } finally {
            setSubmittingContractIndex(-1);
        }
    };

    return (
        <>
            <div className="auth__content-title">Выберите договор</div>
            <div className="auth__content-subtitle">
                Мы нашли несколько договоров, привязанных к&nbsp;вашему телефону
                <br />
                {format(phone.slice(1), '+7 (###) ###-##-##')}
                <Button
                    title={'Изменить'}
                    className={'button--clear-primal'}
                    onClick={() => setStep(AuthorizationModalStep.AUTHORIZATION_BY_PHONE)}
                />
            </div>
            <form onSubmit={onSubmit}>
                <AuthBtnSet modifier={'auth__btnset'}>
                    <div className="auth-btnset__inner">
                        {contracts.map((contract, index) => (
                            <div className="auth__arrow-btn" key={contract['agreement_number']}>
                                <Button
                                    type={'submit'}
                                    className={'button--wide button--clear auth__btn--arrow'}
                                    title={contract['agreement_number']}
                                    icon={'arrow-right'}
                                    onClick={() => onSelectContract(contract, index)}
                                    submitting={submittingContractIndex === index}
                                />
                            </div>
                        ))}
                    </div>
                </AuthBtnSet>
            </form>
        </>
    );
}
